<template>
<div
    v-if="dataSource.length > 0"
    :key="`key_${key}`"
    class="w-100 gb-hot-table-container"
>
    <hot-table
        ref="gbHotTable"
        :settings="hotSettings"
    >
        <hot-column
            title="Student"
            :width="180"
            :read-only="true"
            :settings="{
                data: `student`,
            }"
        >
            <HotCellStudentDetails hot-renderer />
        </hot-column>
        <hot-column
            title="Average"
            :width="84"
            :read-only="true"
            :settings="{
                data: `student`,
            }"
        >
            <HotCellGradeAverage hot-renderer />
        </hot-column>

        <hot-column
            v-for="column in columns"
            :key="column.key"
            :width="112"
            :title="`${column.title}`"
            :settings="{
                data: column.key
            }"
            :renderer="markRenderer"
        />
    </hot-table>

    <b-modal
        v-model="showErrors"
        :hide-header-close="true"
        :no-close-on-esc="true"
        :no-close-on-backdrop="true"
        :centered="true"
    >
        <template #modal-header>
            <h5>A saving error occurred</h5>
        </template>
        <template #default>
            <div class="modal-body">
                <p>
                    The following grades were not saved,
                    please check your network connection,
                    reload the gradebook and try again.
                </p>

                <ul>
                    <li
                        v-for="grade in formattedInvalidGrades"
                        :key="`key_${grade.studentEnrollmentId}_${grade.courseWorkId}`"
                    >
                        <span v-if="grade.student">
                            {{ grade.student.lastName }}, {{ grade.student.firstName }}
                        </span>
                        <span v-else>
                            Unknown Student
                        </span>

                        <span v-if="grade.courseWork">
                            - {{ grade.courseWork.courseWorkTitle }},
                        </span>
                        <span v-else>
                            - Unknown Assignment,
                        </span>

                        <span>
                            Mark: {{ grade.mark }}
                        </span>
                    </li>
                </ul>
            </div>
        </template>
        <template #modal-footer>
            <button
                type="button"
                class="btn btn-success pull-right"
                @click.stop.prevent="reload"
            >
                Reload
            </button>
        </template>
    </b-modal>
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import HotCellStudentDetails from './HotCellStudentDetails.vue';
import HotCellGradeAverage from './HotCellGradeAverage.vue';
import * as lib from '../lib/average';

import Types from '../store/Types';
import hotTableMixins from '../store/mixins/hotTableMixins';
import studentMixins from '../store/mixins/studentMixins';
import panelMixins from '../store/mixins/panelMixins';
import { hotDefaults } from '../store/hotDefaults';

export default Vue.extend({
    name: 'CourseGradebookHotTable',
    components: {
        HotCellStudentDetails,
        HotCellGradeAverage,
    },
    mixins: [
        hotTableMixins,
        studentMixins,
        panelMixins,
    ],
    props: {
        course: {
            type: Object,
            required: true,
        },
        headerRow: {
            type: Boolean,
            required: true,
        },
        gradeTemplate: {
            type: Object,
            required: true,
        },
        assignments: {
            type: Array,
            required: true,
        },
        groupedAssignments: {
            type: Array,
            required: true,
        },
        dataSource: {
            type: Array,
            required: true,
        },
        importSource: {
            type: Array,
            required: true,
        },
        importingGrades: {
            type: Boolean,
            required: true,
        },
        addToImportSource: {
            type: Function,
            required: true,
        },
        comments: {
            type: Array,
            required: true,
        },
        setCourseWorkReOrder: {
            type: Function,
            required: true,
        },
    },
    data() {
        const data = {
            key: 1,
            cachedDataSource: [],
            invalidGrades: [],
            debounced: null,
            debouncedCourseWorkGrades: [],
            hotSettings: {
                autoColumnSize: false,
                autoRowSize: false,
                columnHeaderHeight: 70,
                comments: true,
                contextMenu: ['commentsAddEdit', 'commentsRemove'],
                data: [],
                fillHandle: {
                    autoInsertRow: false,
                    autoInsertCol: false,
                },
                fixedColumnsStart: 2,
                fixedRowsTop: this.headerRow ? 1 : 0,
                rowHeaderWidth: 50,
                rowHeights: 50,
                manualColumnResize: true,
                manualColumnMove: true,
                colHeaders: true,
                cell: this.comments,
            },
        };

        data.hotSettings = {
            ...hotDefaults,
            ...data.hotSettings,
        };

        return data;
    },
    computed: {
        gradebookMode: {
            get() { return this.$store.state.settings.gradebook.gradeBy; },
            set(val) {
                this.$store.commit(Types.mutations.SAVE_SETTING_GRADE_BY, val);
            },
        },
        formattedInvalidGrades() {
            const { invalidGrades } = this;
            const formatted = invalidGrades.map((g) => {
                const { studentEnrollmentId, courseWorkId } = g;
                const student = this.students.find((s) => s.studentEnrollmentId == studentEnrollmentId) || null;
                const courseWork = this.courseWork.find((w) => w.courseWorkId == courseWorkId) || null;
                return {
                    ...g,
                    student,
                    courseWork,
                };
            });
            return formatted;
        },
        showErrors() {
            return this.invalidGrades.length > 0;
        },
        deviceType() {
            return this.$store.state.deviceType;
        },
        user() {
            return this.$store.state.user;
        },
        googleCourseWork() {
            return this.$store.state.database.googleCourseWork;
        },
        courseWork() {
            return this.$store.state.database.courseWork;
        },
        columns() {
            return this.groupedAssignments.map((cw) => {
                const key = (cw.courseWorkDomain == 'Local' ? `cw.${cw.linkingGuid}` : `gcw.${cw.linkingGuid}`);
                const title = cw.teacherExtCourseWorkId ? `${cw.teacherExtCourseWorkId} ${cw.courseWorkTitle}` : cw.courseWorkTitle;
                const column = {
                    ...cw,
                    key,
                    title,
                };
                return column;
            });
        },
        students() {
            if (!this.course) return [];
            const students = this.$_studentMixins_getStudentsForCourse(this.course);
            return students;
        },
        canEditGradebook() {
            const { course, user } = this;
            const { teachers, canEditCourse } = course;
            return canEditCourse && teachers.length > 0 && Boolean(teachers.find((t) => t.schoolStaffId == user.school.schoolStaffId));
        },
    },
    watch: {
        gradebookMode: {
            handler() {
                this.key += 1;
            },
            immediate: true,
        },
        importingGrades() {
            this.populate();
        },
    },
    mounted() {
        window.addEventListener('keydown', this.delaySaving);
        this.populate();
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.delaySaving);
        this.$store.commit(Types.mutations.CLEAR_GRADEBOOK_AVERAGES);
    },
    methods: {
        getInstance() {
            return this.$refs.gbHotTable.hotInstance;
        },
        /*
        reloadAverageForStudentAtIndex(rowIndex) {
            const hotTable = this.$refs.gbHotTable.hotInstance;
            const column = 1; // average column
            const data = hotTable.getDataAtCell(rowIndex, column);
            if (data) {
                hotTable.setDataAtCell(rowIndex, column, { ...data });
            }
        },
        */
        reload() {
            window.location.reload();
        },
        markRenderer(instance, td, row, col, prop, rawMark) {
            const element = td;
            const { headerRow, importingGrades } = this;
            if (row === 0 && headerRow) { // Sticky Header
                const assignment = rawMark;
                this.$_hotTableMixins_setGradebookHeaderProperties(element, assignment);
                return;
            }
            const { gradeTemplate, groupedAssignments, gradebookMode } = this;
            const { allowAllNumerics } = gradeTemplate;
            let pointsPossible = gradeTemplate.defaultMaxPoints;

            const [, linkingGuid] = prop.split('.');
            const assignment = groupedAssignments.find((a) => a.linkingGuid == linkingGuid) || null;

            if (!assignment) {
                window.console.error(`Assignment missing ${linkingGuid}`);
                return;
            }
            pointsPossible = assignment.maxPoints;

            let mark = rawMark;
            if (gradebookMode === 'Percentages' && !Number.isNaN(parseFloat(rawMark))) {
                mark = `${Math.round((parseFloat(rawMark) / pointsPossible) * 100)}`;
            }


            const color = this.$_hotTableMixins_getColorForGradebookMark(mark, gradeTemplate, gradebookMode, pointsPossible);
            this.$_hotTableMixins_setGradebookMarkProperties(element, mark, color, gradebookMode, headerRow, pointsPossible);

            if (importingGrades) {
                const isNumeric = !Number.isNaN(parseFloat(mark));
                const numericMark = isNumeric ? parseFloat(mark) : null;

                // is allowed grade
                const isValidMark = Boolean(gradeTemplate.marks.find((m) => `${m.gradeTemplateMark}` == `${mark}`));

                if (['', null].includes(rawMark)) {
                    element.style.border = '';
                } else if (!isValidMark && !isNumeric) {
                    element.style.border = '1.5px solid #ED0000';
                } else if (isNumeric && !allowAllNumerics) {
                    if (numericMark > assignment.maxPoints && !assignment.allowExtraCredit) {
                        element.style.border = '1.5px solid #ED0000';
                    }
                }
            }

            element.style.fontSize = '1.1rem';
            element.style.fontWeight = '500';
            element.style.color = 'rgb(48, 46, 61)';
        },
        populate() {
            const {
                commitChanges, hotSettings, $store, getInstance,
            } = this;
            const {
                showError, gradeTemplate, dataSource, importSource, addToImportSource, importingGrades, comments, setCourseWorkReOrder,
            } = this;
            const { groupedAssignments, getGradebookMode, columns } = this;
            const { students, canEditGradebook, headerRow } = this;
            const { allowAllNumerics } = gradeTemplate;

            // const { dataSource, columns, $refs } = this;
            if (this.deviceType == 'tablet' || this.deviceType == 'mobile') {
                this.hotSettings.height = document.documentElement.clientHeight - 130;
            } else {
                this.hotSettings.height = document.documentElement.clientHeight - 160;
            }
            this.hotSettings.data = importingGrades ? importSource : dataSource;
            const { data } = this.hotSettings;

            // style row headers
            this.hotSettings.afterGetRowHeader = function (idx, ele) {
                const div = ele.firstChild;
                div.className = 'mt-3';
                const newIndex = !headerRow ? idx + 1 : idx;
                div.innerHTML = `${newIndex == 0 ? '' : newIndex}`;

                const row = dataSource[idx];
                const { student } = row;
                if (student && student.courseSection) {
                    const { courseSectionHexColor } = student.courseSection;
                    if (courseSectionHexColor) {
                        // https://stackoverflow.com/a/66143374
                        const setOpacity = (hex, alpha) => `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, '0')}`;
                        div.parentNode.style = `background-color: ${setOpacity(courseSectionHexColor, 0.2)}`;
                    }
                }
            };

            // style column headers
            const openPanelForCourseWork = this.$_panelMixins_openPanelForCourseWork;
            this.hotSettings.afterGetColHeader = function (idx, ele) {
                const start = 2;

                const container = ele.firstChild;
                container.className = 'mt-2 pt-1 mx-1';
                container.setAttribute('style', 'max-height: 34px; overflow: hidden;');
                if (idx < start) return;
                const text = container.innerText; // store original text

                const courseWork = groupedAssignments[idx - start];
                if (!courseWork) return;

                const { color } = courseWork;

                const a = document.createElement('a');
                a.className = `kt-link font ${color}`;
                a.href = '#';
                a.innerText = text;
                a.setAttribute('style', 'line-height: 16px; white-space: break-spaces; text-decoration-break: clone;');
                a.addEventListener('click', (e) => {
                    e.preventDefault();
                    return openPanelForCourseWork(courseWork);
                });

                container.innerHTML = '';
                container.appendChild(a);
            };

            // pre-save validation
            this.hotSettings.beforeChange = function (changes) {
                const gradebookMode = getGradebookMode();
                const changeArray = changes;
                changes.forEach((change, i) => {
                    // const row = change[0];˜
                    const key = change[1]; // 1 is the changed cell column index key.
                    const isMarkCell = key.indexOf('.') > -1; // key looks like cw.1.3
                    if (!isMarkCell) return;

                    const cellValue = change[3]; // 3 is the changed data index.
                    if (cellValue == null || cellValue == undefined || cellValue == '') {
                        changeArray[i][3] = '';
                        return;
                    }

                    const courseWork = groupedAssignments.find((a) => key.includes(a.linkingGuid));

                    // force 3 chars max, and uppercase
                    let mark = (`${cellValue}` || '').trim().replace('%', '').toUpperCase().substring(0, 3);
                    const isNumeric = !Number.isNaN(parseFloat(mark));
                    if (gradebookMode === 'Percentages' && isNumeric) {
                        mark = `${((parseFloat(mark) / 100) * courseWork.maxPoints)}`;
                    }
                    const numericMark = isNumeric ? parseFloat(mark) : null;

                    // is allowed grade
                    const isValidMark = Boolean(gradeTemplate.marks.find((m) => `${m.gradeTemplateMark}` == `${mark}`));

                    if (isValidMark && !isNumeric) {
                        changeArray[i][3] = mark;
                    } else if (isNumeric) {
                        if (numericMark > courseWork.maxPoints && !courseWork.allowExtraCredit) {
                            showError(`The maximum points for ${courseWork.courseWorkTitle} is ${courseWork.maxPoints}, you entered ${mark}. The assignment does not allow extra credit.`);
                            changeArray[i][3] = courseWork.maxPoints;
                            return;
                        }

                        if ((isNumeric && allowAllNumerics) || (isNumeric && allowAllNumerics === null)) {
                            changeArray[i][3] = mark;
                        } else if (isValidMark) {
                            changeArray[i][3] = mark;
                        } else {
                            if (numericMark > courseWork.maxPoints && courseWork.allowExtraCredit) {
                                const message = `${mark} is not a valid grade template mark above the max points. `
                                    + `Your administrator may need to Allow All Numerics or include ${mark} on the grade template`;
                                showError(message);
                            } else showError(`${mark} is not a valid grade template mark. Your administrator may need to Allow All Numerics or include ${mark} on the grade template`);
                            changeArray[i][3] = '';
                        }
                    } else {
                        showError(`${mark} is not a valid grade template mark. Your administrator may need to include ${mark} on the grade template`);
                        changeArray[i][3] = '';
                    }
                });
            };

            // add comments
            this.hotSettings.beforeSetCellMeta = (rowIndex, columnIndex, key, value) => {
                if (headerRow && rowIndex == 0) return false;
                if (key !== 'comment') return false;

                const filteredComments = comments.filter((c) => c.row == rowIndex && c.col == columnIndex);
                if (!filteredComments.length) return false;

                const { comment } = filteredComments[0];
                const newComment = getInstance().getCellMeta(rowIndex, columnIndex).comment;
                if (!comment) return false;
                if (newComment && newComment.value && !value) return true; // Deleting New Comment
                if (!comment.value && (!value || !value.value)) return false;

                const columnKey = comment.key;
                if (!columnKey) return false;
                if (!canEditGradebook) {
                    if (value) value.value = comment.value || '';
                }
            };
            this.hotSettings.afterSetCellMeta = (rowIndex, columnIndex, key, value) => {
                if (!canEditGradebook) return false;
                if (headerRow && rowIndex == 0) return false;

                const filteredComments = comments.filter((c) => c.row == rowIndex && c.col == columnIndex);
                if (!filteredComments.length) return false;

                const { comment } = filteredComments[0];
                if (value && value.value === comment.value) return false;

                const columnKey = comment.key;
                const { student } = hotSettings.data[rowIndex];
                const [courseWorkType, linkingGuid] = columnKey.split('.');
                if (courseWorkType == 'cw') {
                    const commentValue = value ? value.value : '';
                    const newValue = hotSettings.data[rowIndex][columnKey];
                    const courseWork = $store.state.database.courseWork.find((w) => (w.linkingGuid == linkingGuid && w.courseSectionId == student.courseSectionId)) || null;
                    commitChanges(rowIndex, columnKey, student, courseWork, null, newValue, commentValue);
                }
            };

            // save changes
            this.hotSettings.afterChange = function (changes) {
                if (!changes) return;
                changes.forEach(([rowIndex, columnKey, oldValue, newValue]) => {
                    const { student } = hotSettings.data[rowIndex];
                    const [courseWorkType, linkingGuid] = columnKey.split('.');
                    if (courseWorkType == 'cw') {
                        const meta = comments.filter((c) => c.row == rowIndex && c.comment.key == columnKey)[0];
                        const { comment } = getInstance().getCellMeta(rowIndex, meta.col);
                        const courseWork = $store.state.database.courseWork.find((w) => (w.linkingGuid == linkingGuid && w.courseSectionId == student.courseSectionId)) || null;
                        if (importingGrades) return addToImportSource(courseWork, student, newValue);
                        commitChanges(rowIndex, columnKey, student, courseWork, oldValue, newValue, comment ? comment.value || '' : '');
                    }
                });
            };
            // make specific cells read only
            this.hotSettings.cells = function (row, col, columnKey) {
                if (headerRow && row === 0) return { readOnly: true }; // header row
                if (columnKey == 'student') return { readOnly: true };

                if (!canEditGradebook) return { readOnly: true };

                const keys = data[row];
                if (!keys) return;
                const { student } = keys;

                if (!keys.hasOwnProperty(columnKey)) return { readOnly: true };
                // const mark = keys[columnKey];
                const { studentEnrollmentId, courseSectionId } = student;

                const [courseWorkType, linkingGuid] = columnKey.split('.');

                if (courseWorkType == 'gcw') return { readOnly: true };

                const courseWork = groupedAssignments.find((a) => a.linkingGuid == linkingGuid);
                const studentExists = students.find((s) => s.studentEnrollmentId == studentEnrollmentId);
                if (studentExists && courseWork && courseWork.courseSectionIds.includes(courseSectionId)) {
                    return { readOnly: false };
                }
                return { readOnly: true };
            };

            // get the active cell for floating modal
            this.hotSettings.afterSelectionEnd = function (row, column, row2, column2) {
                if (row !== row2 || column !== column2) return; // only single cell selection
                if (headerRow && row === 0) return; // header row

                const assignment = columns[column - 2]; // student+average columns
                if (!assignment) return;

                const { student } = dataSource[row];

                if (assignment.courseSectionIds.includes(student.courseSectionId)) {
                    const params = {
                        studentEnrollmentId: student.studentEnrollmentId,
                        courseWorkDomain: assignment.courseWorkDomain,
                        linkingGuid: assignment.linkingGuid,
                    };
                    $store.commit(Types.mutations.SET_GRADEBOOK_ACTIVE_CELL, params);
                }
                // console.log(row, column, student, assignment);
            };

            // Handle column dragging
            this.hotSettings.afterColumnMove = function (movedColumns, finalIndex, dropIndex, movePossible, orderChanged) {
                if (!orderChanged) return;

                const newOrder = [];
                columns.forEach((column, index) => {
                    newOrder.push(getInstance().colToProp(index + 2)); // 2 is the offset for student and average columns
                });

                setCourseWorkReOrder(newOrder);
            };

            this.key += 1; // force refresh
        },
        openPanelForCourseWork(courseWork) {
            const { $store, $router, $route } = this;

            if ($store.state.quickPanel.open) {
                return $router.push({
                    name: $route.name, // same page
                    params: $route.params, // self params
                });
            }

            let query = {
                panel: 'cw',
                linkingGuid: courseWork.linkingGuid,
                courseWorkDomain: courseWork.courseWorkDomain,
            };

            if (courseWork.courseWorkDomain == 'Local') {
                query = {
                    ...query,
                    ...{ courseWorkId: courseWork.courseWorkId },
                };
            } else {
                query = {
                    ...query,
                    ...{ googleCourseWorkId: courseWork.googleCourseWorkId },
                };
            }

            $router.push({
                name: $route.name, // same page
                params: $route.params, // self params
                query,
            });
        },
        commitChanges(rowIndex, columnKey, student, courseWork, oldGrade, newGrade, comment) {
            const courseWorkGrade = {
                courseSectionId: courseWork.courseSectionId,
                courseWorkId: courseWork.courseWorkId,
                deleted: newGrade === '' || newGrade === null || newGrade === undefined,
                mark: newGrade === 0 ? '0' : (`${newGrade}` || '').trim().toUpperCase().substring(0, 3),
                schoolTermId: courseWork.schoolTermId,
                studentEnrollmentId: student.studentEnrollmentId,
                comment,
            };
            this.addToQueue(courseWorkGrade);
        },
        addToQueue(grade) {
            const { debouncedCourseWorkGrades } = this;

            // remember unique array of courseWorkGrades in case the user edits the same grade twice
            const unique = new Map();
            debouncedCourseWorkGrades.forEach((g) => { // label keys for previously cached grades
                const key = `${g.studentEnrollmentId}_${g.courseWorkId}_${g.courseSectionId}`;
                unique.set(key, g);
            });

            // add the new grade
            const key = `${grade.studentEnrollmentId}_${grade.courseWorkId}_${grade.courseSectionId}`;
            unique.set(key, { ...grade });
            this.debouncedCourseWorkGrades = [...unique.values()];
            // const saveError = `There was a network error saving a grade for ${student.name} for assignment ${courseWork}. Reload the page and try again.`;
            this.delaySaving();
        },
        delaySaving() {
            if (this.debounced) clearTimeout(this.debounced);
            this.debounced = setTimeout(() => this.saveChanges(), 1200);
        },
        saveChanges() {
            const {
                debouncedCourseWorkGrades, $store,
                showError, invalidGrades,
            } = this;
            if (debouncedCourseWorkGrades.length == 0) return;
            $store.dispatch(Types.actions.SAVE_COURSE_WORK_GRADES, {
                courseWorkGrades: debouncedCourseWorkGrades,
                callback(err, results) {
                    if (err) {
                        window.syncGrades.log(err, 'error', 'gradebook');
                        return showError(err);
                    }

                    if (results.invalidGrades.length > 0) {
                        invalidGrades.push(...results.invalidGrades);
                        window.syncGrades.log(`Some grades were not saved: ${JSON.stringify(results.invalidGrades)}`, 'error', 'gradebook');
                    }

                    if (results.courseWorkGrades.length == 0) {
                        window.syncGrades.log('no grades in callback');
                        return;
                    }

                    const savedGrades = results.courseWorkGrades || [];
                    if (savedGrades.length < 4) {
                        // recalc by student if only a few grades were saved
                        savedGrades.forEach((g) => {
                            lib.calculate.calculateAverageForStudentInCourse($store, g.courseSectionId, g.studentEnrollmentId);
                        });
                    } else {
                        // recalc the whole courseSection if many grades were saved
                        const courseSectionIds = [...new Set(savedGrades.map((g) => g.courseSectionId))];
                        courseSectionIds.forEach((courseSectionId) => {
                            lib.calculate.calculateAverageByCourseSectionId($store, courseSectionId, false);
                        });
                    }
                },
            });
            this.debouncedCourseWorkGrades = [];
        },
        getGradebookMode() {
            return this.gradebookMode;
        },
    },
});

</script>

<style scoped src="../css/colors.scss" lang="scss" />

<style>

.gb-hot-table-container .handsontableInput {
    background-color: white !important;
}
.gb-hot-table-container table th div.relative {
    height: 50px;
    overflow: hidden;
}
.gb-hot-table-container table th span.colHeader {
    white-space: break-spaces;
}
</style>
