<template>
<div :key="`key_${title}_${key}`">
    <CourseSubHeader
        :show-marking-period-picker="!importingGrades"
        :increment-key="incrementKey"
    >
        <template #buttons>
            <span
                v-if="importingGrades"
                v-b-tooltip.hover="`This page is a Beta Feature. Feedback and Bug reports are welcome as it is still receiving additional improvements.`"
                class="kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded py-2"
                style="max-height: 30px; align-self: center;"
            >
                <span class="kt-font-boldest">IMPORT PREVIEW</span>
            </span>
            <button
                type="button"
                class="btn kt-subheader__btn btn-bold btn-upper mr-2"
                :class="{ 'btn-label-primary': gradeBy === 'Points',
                          'btn-label-success': gradeBy === 'Percentages' }"
                @click.stop.prevent="gradeBy = gradeBy === 'Points' ? 'Percentages' : 'Points';"
            >
                {{ gradeBy }}
            </button>

            <!-- <a
                href="#"
                title="Something"
                class="btn btn-icon kt-subheader__btn"
                :class="{ 'active': headerRow }"
                @click.stop.prevent="headerRow = !headerRow"
            >
                <i class="flaticon2-gear" />
            </a> -->

            <!-- <router-link
                :to="{
                    name: $route.name,
                    params: $route.params,
                    query: { panel: 'gb-settings' },
                }"
                title="Show Settings"
                class="btn btn-icon kt-subheader__btn"
            >
                <i class="la la-cog" />
            </router-link> -->
            <a
                v-b-tooltip.hover.bottomleft
                href="#"
                title="Show Sorting Options"
                class="btn btn-icon kt-subheader__btn"
                :class="{ 'active': showSortOptions}"
                @click.stop.prevent="toggleShowSortOptions"
            >
                <i class="fa fa-sort-alpha-down" />
            </a>
            <a
                v-b-tooltip.hover.bottomleft
                href="#"
                title="Show Gradebook Header"
                class="btn btn-icon kt-subheader__btn"
                :class="{ 'active': headerRow }"
                @click.stop.prevent="headerRow = !headerRow"
            >
                <i class="fa fa-columns" />
            </a>
            <a
                v-if="canEditGradebook"
                v-b-tooltip.hover.bottomleft
                href="#"
                title="Student Grade Transfer"
                class="btn btn-icon kt-subheader__btn-primary"
                @click.stop.prevent="toggleShowGradeTransfer"
            >
                <i class="fa fa-sign-in-alt" />
            </a>
            <a
                v-b-tooltip.hover.bottomleft
                href="#"
                title="Gradebook Export / Import"
                class="btn btn-icon kt-subheader__btn-primary"
                @click.stop.prevent="toggleShowImportExport"
            >
                <i class="flaticon-download-1" />
            </a>
        </template>
    </CourseSubHeader>

    <div v-if="isPortfolioReady" class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pr-2 pl-4">
        <div
            v-if="gradebookSource.length == 0"
            class="col"
        >
            <div
                class="alert alert-light alert-elevate fade show"
                role="alert"
            >
                <div class="alert-icon">
                    <i class="la la-info-circle kt-font-danger" />
                </div>
                <div class="alert-text">
                    There are no grades or assignments found. Check the course assignments or roster.
                </div>
            </div>
        </div>

        <div class="kt-portlet p-0 m-0">
            <b-overlay
                :variant="'transparent'"
                opacity="1"
                blur="3px"
                :show="calculating"
            >
                <div
                    v-if="isPortfolioReady"
                    class="kt-portlet__body p-0 m-0"
                >
                    <CourseGradebookHotTable
                        v-if="gradebookSource.length > 0"
                        :key="`key_${key}_${tableKey}`"
                        ref="hotContainer"
                        :course="course"
                        :grade-template="gradeTemplate"
                        :assignments="courseWork"
                        :grouped-assignments="filteredAssignments"
                        :data-source="gradebookSource"
                        :import-source="importSource"
                        :add-to-import-source="addToImportSource"
                        :importing-grades="importingGrades"
                        :header-row="headerRow"
                        :comments="comments"
                        :set-course-work-re-order="setCourseWorkReOrder"
                    />

                    <TheGradebookFloatingPortlet
                        v-if="hasActiveCell"
                        :show="showFloatingPortlet"
                        :course="course"
                        :teacher="teacher"
                        :toggle-show-floating-portlet="toggleShowFloatingPortlet"
                        :floating-export-import-open="showImportExport"
                        :floating-sort-open="showSortOptions"
                    />

                    <TheGradebookFloatingSortOptions
                        v-if="showSortOptions"
                        :show="showSortOptions"
                        :toggle-show-sort-options="toggleShowSortOptions"
                        :sort-by="sortBy"
                        :sort-direction="sortDirection"
                        :set-sort-by="setSortBy"
                        :set-sort-direction="setSortDirection"
                        :floating-="showFloatingPortlet"
                        :floating-export-import-open="showImportExport"
                    />

                    <TheGradebookFloatingExportImport
                        v-if="showImportExport"
                        :show="showImportExport"
                        :toggle-show-import-export="toggleShowImportExport"
                        :export-data="exportData"
                        :import-data="importData"
                        :import-google-data="importGoogleData"
                        :save-imported-data="saveImportedData"
                        :cancel-import="cancelImport"
                        :importing-grades="importingGrades"
                        :saving="saving"
                        :can-edit-gradebook="canEditGradebook"
                        :has-grades="gradebookSource.length > 0"
                    />

                    <CourseGradeTransferModal
                        v-if="showGradeTransfer"
                        :show="showGradeTransfer"
                        :toggle-show-grade-transfer="toggleShowGradeTransfer"
                        :course="course"
                        :increment-key="incrementTableKey"
                    />

                    <TheGradebookGoogleImportModal
                        ref="googleImportModal"
                        :show="showGoogleImport"
                        :google-import-data="googleImportData"
                        :students="students"
                        :course="course"
                        :course-work="courseWork"
                        :gradebook-source="gradebookSource"
                        :set-import-source="setImportSource"
                        :set-importing-grades="setImportingGrades"
                        :toggle-show-google-import="toggleShowGoogleImport"
                        :toggle-show-import-export="toggleShowImportExport"
                    />
                </div>
            </b-overlay>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import moment from 'moment';
import Types from '../store/Types';
import CourseSubHeader from './CourseSubHeader.vue';
import CourseGradebookHotTable from '../components/CourseGradebookHotTable.vue';
import CourseGradeTransferModal from '../components/CourseGradeTransferModal.vue';
import TheGradebookFloatingPortlet from '../components/TheGradebookFloatingPortlet.vue';
import TheGradebookFloatingSortOptions from '../components/TheGradebookFloatingSortOptions.vue';
import TheGradebookFloatingExportImport from '../components/TheGradebookFloatingExportImport.vue';
import TheGradebookGoogleImportModal from '../components/TheGradebookGoogleImportModal.vue';

import courseMixins from '../store/mixins/courseMixins';
import { getCourseWork } from '../store/mixins/courseWorkMixins';
import studentMixins from '../store/mixins/studentMixins';
import portfolioMixins from '../store/mixins/portfolioMixins';
import averagingMixins from '../store/mixins/averagingMixins';
import teacherMixins from '../store/mixins/teacherMixins';
import { courseWorkGradeMixins, getCourseWorkGrades } from '../store/mixins/courseWorkGradeMixins';

import * as lib from '../lib/average';
import { gradeBookImport, googleImport } from '../lib/uploads/gradeBook';

export default Vue.extend({
    name: 'CourseGradebook',
    components: {
        CourseSubHeader,
        CourseGradebookHotTable,
        CourseGradeTransferModal,
        TheGradebookFloatingPortlet,
        TheGradebookFloatingSortOptions,
        TheGradebookFloatingExportImport,
        TheGradebookGoogleImportModal,
    },
    mixins: [
        portfolioMixins,
        courseMixins,
        teacherMixins,
        studentMixins,
        averagingMixins,
        courseWorkGradeMixins,
    ],
    data() {
        return {
            key: 0,
            tableKey: 0,
            hotRef: null,
            height: 100,
            debounce: null,
            searchString: '',
            headerRow: false,
            showSortOptions: false,
            showGradeTransfer: false,
            showImportExport: false,
            showFloatingPortlet: false,
            showGoogleImport: false,
            manuallyClosedFloatingPortlet: false,
            sortings: [],
            sortBy: null,
            sortDirection: null,
            comments: [],
            courseWorkReOrder: [],
            importSource: [],
            importingGrades: false,
            googleImportData: [],
            saving: false,
        };
    },
    computed: {
        gradeBy: {
            get() {
                return this.$store.state.settings.gradebook.gradeBy;
            },
            set(val) {
                this.$store.commit(Types.mutations.SAVE_SETTING_GRADE_BY, val);
            },
        },
        calculating() {
            const { cache } = this.$store.state.database;
            return Boolean(cache.find((k) => k.status == 'loading' && k.cacheType == 'portfolioCalc') || null);
        },
        windowSize() {
            return `${this.$store.state.window.width}_${this.$store.state.window.height}`;
        },
        hasActiveCell() {
            return this.$store.state.database.gradebook.activeCell.studentEnrollmentId;
        },
        user() {
            return this.$store.state.user;
        },
        isPortfolioReady() {
            const cacheKey = this.$_portfolioMixins_getPortfolioCacheKey;
            return Boolean(this.$store.state.database.cache.find((c) => c.key == cacheKey && c.status == 'cached'));
        },
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId;
        },
        course() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        teacher() {
            return this.$_teacherMixins_getTeacherFromRoute();
        },
        students() {
            if (!this.course || !this.isPortfolioReady) return [];
            const students = this.$_studentMixins_getStudentsForCourse(this.course);
            return students;
        },
        schoolTermMarkingPeriodId: {
            get() {
                return this.$store.state.settings.schoolTermMarkingPeriodId;
            },
            set(schoolTermMarkingPeriodId) {
                this.$store.commit(Types.mutations.SAVE_SETTING_SCHOOL_TERM_MARKING_PERIOD, schoolTermMarkingPeriodId);
                this.key += 1;
            },
        },
        markingPeriods() {
            const { markingPeriods } = this.$store.state.database;
            if (!markingPeriods || !markingPeriods.length) return [];
            return markingPeriods
                .filter((mp) => !mp.deleted)
                .sort((a, b) => ((a.markingPeriod > b.markingPeriod) ? 1 : ((b.markingPeriod > a.markingPeriod) ? -1 : 0)));
        },
        currentMarkingPeriod() {
            const { markingPeriods, schoolTermMarkingPeriodId } = this;
            if (!markingPeriods || !markingPeriods.length) return null;
            return markingPeriods.find((mp) => mp.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId) || null;
        },
        gradeTemplate() {
            if (!this.course) return null;
            return this.course.gradeTemplate;
        },
        title() {
            return this.$route.name;
        },
        studentAverages() {
            const { course } = this;
            if (!course) return [];
            return this.$_averagingMixins_getAveragesForCourse(course);
        },
        showScaledScores() {
            if (!this.gradeTemplate) return false;
            const { schoolScaledDisplayPreference } = this.gradeTemplate;
            return ['ShowScale', 'All'].includes(schoolScaledDisplayPreference);
        },
        showPercentages() {
            if (!this.gradeTemplate) return false;
            const { schoolScaledDisplayPreference } = this.gradeTemplate;
            return ['ShowPercentage', 'All'].includes(schoolScaledDisplayPreference);
        },
        gradeCategories() {
            if (!this.gradeTemplate) return [];
            return this.gradeTemplate.categories;
        },
        courseWorkGrades() {
            const { course } = this;
            if (!course) return [];
            const { database } = this.$store.state;
            const grades = getCourseWorkGrades(database, course);
            return grades;
        },
        courseWork() {
            const { course } = this;
            if (!course) return [];
            const { database } = this.$store.state;
            const courseWork = getCourseWork(database, course);
            return courseWork.filter((a) => !a.deleted).sort((a, b) => (`${a.publishDate}${a.publishTime || '12:00 AM'}` < `${b.publishDate}${b.publishTime || '12:00 AM'}` ? 1 : -1));
        },
        groupedAssignments() {
            const {
                schoolTermMarkingPeriodId, gradeCategories,
                sortBy, sortDirection, courseWorkReOrder,
            } = this;

            const map = new Map();
            this.courseWork.forEach((assignment) => {
                const cw = { ...assignment };
                const { linkingGuid, courseWorkId, courseSectionId } = cw;
                const courseWork = map.get(linkingGuid);
                if (courseWork) {
                    courseWork.courseWorkIds.push(courseWorkId);
                    courseWork.courseSectionIds.push(courseSectionId);
                    map.set(linkingGuid, { ...courseWork });
                } else {
                    cw.courseWorkIds = [courseWorkId];
                    cw.courseSectionIds = [courseSectionId];
                    delete cw.courseWorkId;
                    delete cw.courseSectionId;
                    map.set(linkingGuid, { ...cw });
                }
            });
            const columns = [...map.values()];
            return columns
                .filter((c) => schoolTermMarkingPeriodId == null || c.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId)
                .sort((a, b) => {
                    if (!sortBy || !sortDirection) return 0;

                    if (sortBy === 'Coursework') {
                        if (sortDirection === 'A-Z Ascending') return a.courseWorkTitle.localeCompare(b.courseWorkTitle);
                        if (sortDirection === 'A-Z Descending') return b.courseWorkTitle.localeCompare(a.courseWorkTitle);
                        if (sortDirection === 'Points Ascending') return (a.maxPoints * a.courseWorkWeight) - (b.maxPoints * b.courseWorkWeight);
                        if (sortDirection === 'Points Descending') return (b.maxPoints * b.courseWorkWeight) - (a.maxPoints * a.courseWorkWeight);
                    }
                    if (sortBy === 'Coursework Category') {
                        if (sortDirection === 'A-Z Ascending') return a.categoryName.localeCompare(b.categoryName);
                        if (sortDirection === 'A-Z Descending') return b.categoryName.localeCompare(a.categoryName);
                        if (gradeCategories.length > 0) {
                            const aCategory = gradeCategories.find((c) => c.gradeTemplateCategoryId == a.gradeTemplateCategoryId) || null;
                            const bCategory = gradeCategories.find((c) => c.gradeTemplateCategoryId == b.gradeTemplateCategoryId) || null;

                            if (sortDirection === 'Points Ascending') {
                                const direction = parseFloat(aCategory ? aCategory.percentage : 0) - parseFloat(bCategory ? bCategory.percentage : 0);
                                if (direction === 0) return a.categoryName.localeCompare(b.categoryName);
                                return direction;
                            }
                            if (sortDirection === 'Points Descending') {
                                const direction = parseFloat(bCategory ? bCategory.percentage : 0) - parseFloat(aCategory ? aCategory.percentage : 0);
                                if (direction === 0) return a.categoryName.localeCompare(b.categoryName);
                                return direction;
                            }
                        }
                    }
                    if (sortBy === 'Due Date') {
                        if (sortDirection === 'Ascending') return `${a.dueDate || ''}${a.dueTime || ''}`.localeCompare(`${b.dueDate || ''}${b.dueTime || ''}`);
                        if (sortDirection === 'Descending') return `${b.dueDate || ''}${b.dueTime || ''}`.localeCompare(`${a.dueDate || ''}${a.dueTime || ''}`);
                    }
                    if (sortBy === 'Publish Date') {
                        if (sortDirection === 'Ascending') return `${a.publishDate || ''}${a.publishTime || ''}`.localeCompare(`${b.publishDate || ''}${b.publishTime || ''}`);
                        if (sortDirection === 'Descending') return `${b.publishDate || ''}${b.publishTime || ''}`.localeCompare(`${a.publishDate || ''}${a.publishTime || ''}`);
                    }

                    return 0;
                }).map((courseWork, idx) => {
                    const cw = { ...courseWork };
                    if (!courseWorkReOrder) {
                        cw.index = idx;
                        return cw;
                    }
                    const courseWorkOrder = courseWorkReOrder.find((o) => cw.courseWorkIds.includes(o.courseWorkId)) || null;
                    cw.index = courseWorkOrder ? courseWorkOrder.index : idx;
                    return cw;
                }).sort((a, b) => a.index - b.index);
        },
        filteredAssignments() {
            const { searchString, groupedAssignments } = this;
            if (searchString.length > 0) {
                return groupedAssignments
                    .filter((c) => c.courseWorkTitle.toLowerCase().includes(searchString.toLowerCase()) || `${c.categoryName || ''}`.toLowerCase().includes(searchString.toLowerCase()));
            }
            return groupedAssignments;
        },
        gradebookSource() {
            const primativeMarkRows = [];
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.comments = [];
            const {
                gradeTemplate, students, canEditGradebook,
                headerRow,
            } = this;
            const { groupedAssignments, courseWorkGrades } = this;
            if (!gradeTemplate || students.length == 0 || groupedAssignments.length == 0) return [];

            if (headerRow) {
                const gradebookStickyRow = {
                    student: null,
                    average: null,
                };
                groupedAssignments.forEach((cw) => {
                    const key = cw.linkingGuid && cw.courseWorkDomain === 'Local' ? `cw.${cw.linkingGuid}` : `gcw.${cw.linkingGuid}`;
                    gradebookStickyRow[key] = cw;
                });
                primativeMarkRows.push(gradebookStickyRow);
            }

            students.forEach((student, rowIdx) => {
                const { courseSection, studentEnrollmentId, courseSectionStudentId } = student;
                const { courseSectionId } = courseSection;

                // all assignments
                const primativeMarkRow = {
                    student: { // for HotCellStudentDetails
                        courseSection,
                        courseSectionId,
                        courseSectionStudentId,
                        student,
                        studentEnrollmentId,
                        name: `${student.lastName}, ${student.firstName}`,
                    },
                };

                const studentGrades = courseWorkGrades.filter((g) => {
                    if (g.googleCourseWorkId) return g.studentEnrollmentId == studentEnrollmentId;
                    return g.studentEnrollmentId == studentEnrollmentId
                        && g.courseSectionId == courseSectionId
                        && !g.deleted;
                }) || null;

                groupedAssignments.forEach((cw, colIdx) => {
                    const grade = studentGrades.find((g) => {
                        if (g.courseWorkId) return cw.courseWorkIds.includes(g.courseWorkId);
                        if (g.googleCourseWorkId) return cw.googleCourseWorkId === g.googleCourseWorkId;
                        return false;
                    }) || null;
                    let primativeMark = '';
                    if (grade) {
                        if (grade.hasOwnProperty('mark')) primativeMark = grade.mark !== null && grade.mark !== '' ? grade.mark : '';
                        if (primativeMark === '' && (grade.hasOwnProperty('assignedGrade') || grade.hasOwnProperty('draftGrade'))) {
                            primativeMark = grade.assignedGrade !== null && grade.assignedGrade !== '' ? grade.assignedGrade
                                : grade.draftGrade !== '' && grade.draftGrade !== null ? grade.draftGrade : '';
                        }
                    }

                    const key = cw.linkingGuid && cw.courseWorkDomain === 'Local' ? `cw.${cw.linkingGuid}` : `gcw.${cw.linkingGuid}`;
                    primativeMarkRow[key] = primativeMark;

                    const value = grade ? grade.comment : '';

                    this.comments.push({
                        row: headerRow ? rowIdx + 1 : rowIdx,
                        col: colIdx + 2, // Student | Average | ...
                        comment: { value, key, readOnly: !canEditGradebook },
                    });
                });

                // add the whole row to the dataSource
                primativeMarkRows.push(primativeMarkRow);
            });

            return primativeMarkRows;
        },
        canEditCourse() {
            return this.course.canEditCourse || false;
        },
        averagesCached() {
            const { extCourseSectionId } = this;
            const { schoolTermMarkingPeriodId } = this.$store.state.settings;
            const cache = this.$store.state.database.cache.find((c) => c.key == `portfolioCalc_${extCourseSectionId}_${schoolTermMarkingPeriodId}`) || null;
            return Boolean(cache);
        },
        canEditGradebook() {
            const { course, user } = this;
            const { teachers, canEditCourse } = course;
            return canEditCourse && teachers.length > 0 && Boolean(teachers.find((t) => t.schoolStaffId == user.school.schoolStaffId));
        },
    },
    watch: {
        extCourseSectionId() {
            this.sortBy = null;
            this.sortDirection = null;
            this.courseWorkReOrder = [];
            this.key += 1;
        },
        schoolTermMarkingPeriodId() {
            const { sortings, schoolTermMarkingPeriodId } = this;
            this.sortBy = null;
            this.sortDirection = null;
            this.courseWorkReOrder = [];

            if (sortings && sortings.length) {
                const markingPeriodSort = sortings.find((s) => s.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId) || null;
                if (markingPeriodSort) {
                    this.sortBy = markingPeriodSort.sortBy || null;
                    this.sortDirection = markingPeriodSort.sortDirection || null;
                    this.courseWorkReOrder = markingPeriodSort.courseWorkReOrder || [];
                }
            }
        },
        windowSize() {
            // redraw the table when the window size changes
            this.tableKey += 1;
        },
        headerRow: {
            handler() {
                this.tableKey += 1;
            },
            immediate: true,
        },
        hasActiveCell() {
            if (this.hasActiveCell && !this.manuallyClosedFloatingPortlet) this.showFloatingPortlet = true;
        },
        sortDirection() {
            if (this.sortDirection) this.saveSortings();
        },
        courseWorkReOrder() {
            if (this.courseWorkReOrder.length) this.saveSortings();
            this.tableKey += 1;
        },
    },
    beforeDestroy() {
        this.saveSortings();
    },
    mounted() {
        const { course, schoolTermMarkingPeriodId } = this;
        if (!course) return;

        const { courseSectionId } = course;
        if (!courseSectionId) return;

        const { sortings } = this.$store.state.settings.gradebook;
        if (sortings && sortings.length) {
            const courseSortings = sortings.find((s) => s.courseSectionId == courseSectionId) || null;
            if (courseSortings) {
                this.sortings = courseSortings.sortings || [];
                const markingPeriodSort = this.sortings.find((s) => s.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId) || null;
                if (markingPeriodSort) {
                    this.sortBy = markingPeriodSort.sortBy || null;
                    this.sortDirection = markingPeriodSort.sortDirection || null;
                    this.courseWorkReOrder = markingPeriodSort.courseWorkReOrder || [];
                }
            }
        }
    },
    methods: {
        incrementKey() {
            this.key += 1;
        },
        incrementTableKey() {
            this.tableKey += 1;
        },
        calculateAverages() {
            const { extCourseSectionId, isPortfolioReady, averagesCached } = this;
            if (!isPortfolioReady) return;
            if (!averagesCached) {
                this.$_averagingMixins_calculateAverageByExtCourseSectionId(extCourseSectionId);
            }
        },
        reloadAverageForStudentAtIndex(index) {
            const hotTable = this.$refs.hotContainer.$refs.gbHotTable.hotInstance;
            const row = index + 1; // first row is header thingy
            const column = 1; // average column
            const data = hotTable.getDataAtCell(row, column);
            if (data) {
                hotTable.setDataAtCell(row, column, { ...data });
            }
        },
        importData(file) {
            const v = this;
            const { user, students, gradebookSource, course } = v;
            const { courseSectionId } = course;
            gradeBookImport(file, user, (err, results) => {
                if (err) return v.showError(err);

                const output = results.map((row) => {
                    const record = { ...row };

                    const student = students.find((s) => `${s.lastName}, ${s.firstName}` === row.student) || null;
                    if (!student) return null;

                    record.student = {
                        courseSection: course,
                        courseSectionId,
                        courseSectionStudentId: student.courseSectionStudentId,
                        student,
                        studentEnrollmentId: student.studentEnrollmentId,
                        name: `${student.lastName}, ${student.firstName}`,
                    }

                    const gradebookSourceRow = gradebookSource.find((r) => r.student && r.student.studentEnrollmentId == record.student.studentEnrollmentId) || null;
                    if (!gradebookSourceRow) return null;

                    Object.entries(row).forEach(([key, value]) => {
                        if (key === 'student') return;
                        const newKey = `cw.${key}`;
                        if (!gradebookSourceRow.hasOwnProperty(newKey)) {
                            delete record[key];
                            return;
                        }
                        record[newKey] = value;
                        delete record[key];
                    });

                    Object.entries(gradebookSourceRow).forEach(([key, value]) => {
                        if (key === 'student' || key === 'average') return;
                        if (!record.hasOwnProperty(key)) {
                            record[key] = value;
                        }
                    });

                    return record;
                }).filter((r) => r);

                gradebookSource.forEach((row) => {
                    const student = output.find((r) => r.student.studentEnrollmentId == row.student.studentEnrollmentId) || null;
                    if (student) return;
                    output.push(row);
                });

                output.sort((a, b) => {
                    if (a.student.name < b.student.name) return -1;
                    if (a.student.name > b.student.name) return 1;
                    return 0;
                });

                if (!output.length) return v.showError('No matching assignments/valid grades found in file. Select correct course or switch to correct marking period for upload');
                v.importSource = output;
                v.importingGrades = true;
                v.showImportExport = true;
            });
        },
        importGoogleData(file) {
            const v = this;
            const { user, students } = v;
            googleImport(file, user, (err, results) => {
                if (err) return v.showError(err);

                const output = results.map((row, idx) => {
                    const record = { ...row };

                    if (idx === 0) return record;

                    record.student = students.find((s) => s.schoolEmail == row.email) || null;
                    if (!record.student) return null;

                    return record;
                }).filter((r) => r);

                if (!output.length) return v.showError('No Google Import Data to import');
                v.googleImportData = output;
                v.showGoogleImport = true;
                v.showImportExport = false;
            });
        },
        addToImportSource(courseWork, student, mark) {
            const { linkingGuid } = courseWork;
            this.importSource = this.importSource.map((row) => {
                if (row.student.studentEnrollmentId !== student.studentEnrollmentId) return row;
                const record = { ...row };
                record[`cw.${linkingGuid}`] = mark;
                return record;
            });
        },
        saveImportedData() {
            const v = this;
            if (v.saving) return;
            v.saving = true;
            const {
                $store, importSource, gradebookSource, gradeTemplate, showError,
            } = v;

            const { allowAllNumerics } = gradeTemplate;
            const invalidGrades = [];

            const courseWorkGrades = importSource.reduce((acc, row) => {
                const { student } = row;
                if (!student) return acc;

                const { studentEnrollmentId } = student;
                if (!studentEnrollmentId) return acc;

                const gradebookSourceRow = gradebookSource.find((r) => r.student && r.student.studentEnrollmentId == studentEnrollmentId) || null;
                if (!gradebookSourceRow) return null;

                const newGrades = Object.entries(row).map(([key, rawMark]) => {
                    if (key === 'student' || key === 'average' || key.startsWith('gcw')) return null;
                    const mark = (`${rawMark}` || '').trim();

                    const linkingGuid = key.replace('cw.', '');
                    const courseWork = v.courseWork.find((c) => c.linkingGuid == linkingGuid) || null;
                    if (!courseWork) return null;

                    const oldMark = gradebookSourceRow[key] || null;
                    if (mark === oldMark) return null;

                    const { schoolTermId, courseSectionId, courseWorkId } = courseWork;
                    // Validate Against Grade Template
                    const isNumeric = !Number.isNaN(parseFloat(mark));
                    const numericMark = isNumeric ? parseFloat(mark) : null;
                    const isValidMark = Boolean(gradeTemplate.marks.find((m) => `${m.gradeTemplateMark}` == `${mark}`));

                    if (isValidMark && !isNumeric) {
                        return {
                            courseSectionId,
                            courseWorkId,
                            deleted: ['', null, undefined].includes(mark),
                            mark: `${mark || ''}`,
                            schoolTermId,
                            studentEnrollmentId,
                            comment: '',
                        };
                    } if (isNumeric) {
                        if (numericMark > courseWork.maxPoints && !courseWork.allowExtraCredit) {
                            return {
                                courseSectionId,
                                courseWorkId,
                                deleted: ['', null, undefined].includes(mark),
                                mark: `${courseWork.maxPoints}`,
                                schoolTermId,
                                studentEnrollmentId,
                                comment: '',
                            };
                        }

                        if ((isNumeric && allowAllNumerics) || (isNumeric && allowAllNumerics === null)) {
                            return {
                                courseSectionId,
                                courseWorkId,
                                deleted: ['', null, undefined].includes(mark),
                                mark: `${mark || ''}`,
                                schoolTermId,
                                studentEnrollmentId,
                                comment: '',
                            };
                        }
                        if (isValidMark) {
                            return {
                                courseSectionId,
                                courseWorkId,
                                deleted: ['', null, undefined].includes(mark),
                                mark: `${mark || ''}`,
                                schoolTermId,
                                studentEnrollmentId,
                                comment: '',
                            };
                        }
                        invalidGrades.push(mark);
                        return null;
                    }

                    if (['', null, undefined].includes(mark) && !['', null, undefined].includes(oldMark)) {
                        return {
                            courseSectionId,
                            courseWorkId,
                            deleted: true,
                            mark: '',
                            schoolTermId,
                            studentEnrollmentId,
                            comment: '',
                        };
                    }

                    if (['', null, undefined].includes(mark)) return null;
                    invalidGrades.push(mark);
                    return null;
                }).filter((g) => g);
                return [...acc, ...newGrades];
            }, []);

            if (invalidGrades.length) {
                showError(`${invalidGrades.length} grades from csv will not be saved as they are invalid for grade template`);
            }

            if (!courseWorkGrades.length) {
                showError('No grades to save');
                v.saving = false;
                v.importSource = [];
                v.importingGrades = false;
                return;
            }

            $store.dispatch(Types.actions.SAVE_COURSE_WORK_GRADES, {
                courseWorkGrades,
                callback(err, results) {
                    v.saving = false;
                    v.importSource = [];
                    v.importingGrades = false;
                    if (err) {
                        window.syncGrades.log(err, 'error', 'gradebook');
                        return showError(err);
                    }

                    v.showImportExport = false;
                    if (!results.courseWorkGrades.length) return;
                    const savedGrades = results.courseWorkGrades || [];
                    if (savedGrades.length < 4) {
                        // recalc by student if only a few grades were saved
                        savedGrades.forEach((g) => {
                            lib.calculate.calculateAverageForStudentInCourse($store, g.courseSectionId, g.studentEnrollmentId);
                        });
                    } else {
                        // recalc the whole courseSection if many grades were saved
                        const courseSectionIds = [...new Set(savedGrades.map((g) => g.courseSectionId))];
                        courseSectionIds.forEach((courseSectionId) => {
                            lib.calculate.calculateAverageByCourseSectionId($store, courseSectionId, false);
                        });
                    }
                },
            });
        },
        setImportSource(data) {
            this.importSource = data;
        },
        setImportingGrades(value) {
            this.importingGrades = value;
        },
        cancelImport() {
            this.importSource = [];
            this.importingGrades = false;
        },
        exportData() {
            const { schoolTermMarkingPeriodId, studentAverages } = this;
            const { columns } = this.$refs.hotContainer;
            const csvColumns = [{ key: 'student', title: 'Student' }, { key: 'average', title: 'Average' }, ...columns];

            const { data } = this.$refs.hotContainer.$refs.gbHotTable._props.settings;
            const labelsData = csvColumns.map((column) => column.key);
            let metaRow = '';
            let csv = csvColumns
                .map((header) => {
                    const { linkingGuid } = header;
                    if (linkingGuid) metaRow += `${header.linkingGuid},`;
                    return `"${header.title}"`;
                })
                .join(',');
            csv += '\n';
            data.forEach((row) => {
                csv += labelsData
                    .map((cell) => {
                        if (cell === 'average') {
                            if (!row.student) return '';
                            const { studentEnrollmentId } = row.student;
                            const average = studentAverages.find((a) => a.studentEnrollmentId == studentEnrollmentId && a.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId) || null;
                            return average ? `"${average.mark}"` : '';
                        }
                        if (row[cell]) {
                            if (row[cell].name) return `"${row[cell].name}"`;
                            if (row[cell].hasOwnProperty('mark')) return row[cell].mark || '';
                            return row[cell];
                        }
                        return '';
                    })
                    .join(',');
                csv += '\n';
            });

            // Add the courseWork MetaData row for importing
            csv = `DO NOT EDIT/ALTER ROW,,${metaRow}\n\n${csv}`;

            const date = moment(new Date()).format('YYYYMMDD');
            const filename = `${this.course.name}_Gradebook_${date}`.replaceAll(' ', '_');
            const anchor = document.createElement('a');
            anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
            anchor.target = '_blank';
            anchor.download = `${filename}.csv`;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        },
        toggleShowGradeTransfer() {
            this.showGradeTransfer = !this.showGradeTransfer;
        },
        toggleShowSortOptions() {
            this.showSortOptions = !this.showSortOptions;
        },
        toggleShowImportExport() {
            this.showImportExport = !this.showImportExport;
        },
        toggleShowFloatingPortlet() {
            this.showFloatingPortlet = !this.showFloatingPortlet;
            if (!this.showFloatingPortlet) this.manuallyClosedFloatingPortlet = true;
        },
        toggleShowGoogleImport() {
            if (this.showGoogleImport) this.googleImportData = [];
            this.showGoogleImport = !this.showGoogleImport;
        },
        updateSortings() {
            const {
                schoolTermMarkingPeriodId, sortBy, sortDirection, courseWorkReOrder,
            } = this;

            if ((!sortBy || !sortDirection) && courseWorkReOrder.length === 0) return;

            const unique = new Map();
            this.sortings.forEach((row) => {
                const key = `${row.schoolTermMarkingPeriodId}`;
                unique.set(key, row);
            });

            [{
                sortBy, sortDirection, schoolTermMarkingPeriodId, courseWorkReOrder,
            }].forEach((row) => {
                const key = `${schoolTermMarkingPeriodId}`;
                unique.set(key, row);
            });

            this.sortings = [...unique.values()];
        },
        saveSortings() {
            const { course, sortings } = this;
            if (!course || !sortings.length) return;

            const { courseSectionId } = course;
            if (!courseSectionId) return;

            const setting = {
                courseSectionId,
                sortings,
            };
            this.$store.commit(Types.mutations.SAVE_SETTING_GRADEBOOK_SORTINGS, [setting]);
        },
        setSortBy(sortBy) {
            this.sortBy = sortBy;
            this.sortDirection = null;
            this.courseWorkReOrder = [];
        },
        setSortDirection(sortDirection) {
            this.sortDirection = sortDirection;
            this.courseWorkReOrder = [];
            this.updateSortings();
        },
        setCourseWorkReOrder(newOrder) {
            const { groupedAssignments } = this;

            this.courseWorkReOrder = newOrder.map((guid, idx) => {
                const assignment = groupedAssignments.find((a) => a.linkingGuid === guid.replace('cw.', '').replace('gcw.', '')) || null;
                if (!assignment) return null;

                const { courseWorkIds } = assignment;
                if (!courseWorkIds || courseWorkIds.length === 0) return null;

                return {
                    courseWorkId: assignment.courseWorkIds[0],
                    index: idx,
                };
            }).filter((a) => a);

            this.updateSortings();
        },
    },
});
</script>
